.MuiInputAdornment-root {
    color: #f4f0f0;
}

.MuiFormLabel-root {
    color: #f4f0f0;
}

.MuiInput-underline:before {
    border-bottom-color: rgba(244, 240, 240, 0.4) !important;
}

.MuiInput-underline:after {
    color: #f4f0f0;
}

.MuiSvgIcon-root {
    color: #f4f0f0;
}

.MuiInputLabel-formControl {
    color: #f4f0f0;
}