.container {
  width: 100%;
  height: 100%;
  transition: all 0.2s linear;
  position: relative;
}

.container:hover .text {
  opacity: 1;
  transition: all 0.2s linear;
}

.text {
  opacity: 0;
  transition: .5s ease;
}


.textSmall {
  opacity: 1;
}
