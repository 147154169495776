.container {
  width: 100%;
  height: 100%;
  transition: all 0.2s linear;
  position: relative;
}

.containerNT {
  width: 100%;
  height: 100%;
  position: relative;
}

.container:hover .overlay {
  opacity: 1;
  transition: all 0.2s linear;
}

.container:hover .containerImage {
  -webkit-filter: brightness(65%);
  -moz-filter: brightness(65%);
  -o-filter: brightness(65%);
  filter: brightness(65%);
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

.containerImageNT {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  -webkit-filter: brightness(75%);
  -moz-filter: brightness(75%);
  -o-filter: brightness(75%);
  filter: brightness(75%);
}

.overlay {
  width: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
  transition: .5s ease;
  cursor: pointer
}
